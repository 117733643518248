import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Load user info from cookies on app start
  useEffect(() => {
    const userInfoCookie = Cookies.get('userInfo');
    if (userInfoCookie) {
      setUser(JSON.parse(userInfoCookie));
      console.log('Loaded user from cookies:', JSON.parse(userInfoCookie));
    } else {
      setUser(null);
      console.log('No user info found in cookies');
    }
  }, []);  

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

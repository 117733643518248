import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './InputBox.css';

const InputBox = ({ placeholder, size = 'medium', icon, value, onChange, onBlur, onKeyDown }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = (e) => {
    setIsFocused(false);
    if (onBlur) onBlur(e);
  };

  const inputClasses = `
    input-box 
    input-${size} 
    ${isFocused ? 'input-focused' : ''} 
    ${icon ? 'input-with-icon' : ''}
  `;

  return (
    <div className="input-container">
      {icon && <img src={icon} alt="icon" className="input-icon" />}
      <input
        type="text"
        className={inputClasses}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

InputBox.propTypes = {
  placeholder: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  icon: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
};

export default InputBox;

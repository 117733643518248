import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './SearchableSingleSelectDropdown.css';
import searchIcon from '../../assets/icons/search.svg';
import downArrowIcon from '../../assets/icons/down-arrow.svg';

const SearchableSingleSelectDropdown = ({
  data,
  onSelect,
  placeholder,
  size = 'medium',
  initialValue = '',
}) => {
  const [searchTerm, setSearchTerm] = useState(initialValue);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(initialValue);
  const containerRef = useRef(null);

  useEffect(() => {
    setSearchTerm(initialValue);
    setSelectedOption(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (searchTerm.length >= 3) {
      const filtered = data.filter((item) =>
        item.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions([]);
    }
  }, [searchTerm, data]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setSearchTerm(option);
    setIsOpen(false);
    onSelect(option);
  };

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleOutsideClick = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, []);

  return (
    <div className={`dropdown-container dropdown-${size}`} ref={containerRef}>
      <div className="dropdown-input" onClick={toggleDropdown}>
        <img src={searchIcon} alt="Search" className="dropdown-icon-left" />
        <input
          type="text"
          placeholder={placeholder}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="dropdown-text-input"
        />
        <img src={downArrowIcon} alt="Arrow" className="dropdown-icon-right" />
      </div>

      {isOpen && filteredOptions.length > 0 && (
        <div className="dropdown-options">
          {filteredOptions.map((option, index) => (
            <div
              key={index}
              className={`dropdown-option ${selectedOption === option ? 'selected' : ''}`}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

SearchableSingleSelectDropdown.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  initialValue: PropTypes.string,
};

export default SearchableSingleSelectDropdown;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Checkbox.css';

const Checkbox = ({ label, checked, onChange, variant = 'single', required = false }) => {
  const [isChecked, setIsChecked] = useState(checked || false);

  const handleChange = () => {
    const newChecked = variant === 'multi' ? !isChecked : true; // Single select can only be true
    setIsChecked(newChecked);
    onChange(newChecked);
  };

  return (
    <div className="checkbox-container">
      <input
        type="checkbox"
        className="checkbox-input"
        checked={isChecked}
        onChange={handleChange}
        required={required}
      />
      <label className="checkbox-label">{label}</label>
    </div>
  );
};

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired, // Allows JSX and strings
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['single', 'multi']),
  required: PropTypes.bool,
};

export default Checkbox;

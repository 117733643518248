import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Demo from './pages/Demo';
import Register from './pages/Register';
import Login from './pages/Login';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ConsentModal from './components/modals/ConsentModal';
import SeekerDashboard from './pages/SeekerDashboard';
import SeekerWrapper from './wrappers/SeekerWrapper';
import AdminWrapper from './wrappers/AdminWrapper';
import SupplierWrapper from './wrappers/SupplierWrapper';
import { UserProvider } from './components/userData/UserContext';
import Navbar from './components/navbar/Navbar';
import Home from './pages/Demo';
import SeekerProfileEdit from './pages/SeekerProfileEdit';
import SeekerProfile from './pages/SeekerProfile';
import ForgotPassword from './pages/ForgotPassword';
import TermsAndConditions from './pages/TermsAndConditions';
import AccountSettings from './pages/AccountSettings';

function App() {
  const [showConsentModal, setShowConsentModal] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) setShowConsentModal(true);
  }, []);

  const handleConsent = (consentType) => {
    localStorage.setItem('cookieConsent', consentType);
    setShowConsentModal(false);
  };

  return (
    <UserProvider>
      <Router>
        <Navbar />
        <div className="main-content"> 
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/seeker-dashboard" element={<SeekerWrapper><SeekerDashboard /></SeekerWrapper>} />
          <Route path="/seeker-account-settings" element={<SeekerWrapper><AccountSettings /></SeekerWrapper>} />
          <Route path="/seeker-profile-edit" element={<SeekerWrapper><SeekerProfileEdit /></SeekerWrapper>} />
          <Route path="/seeker-profile" element={<SeekerWrapper><SeekerProfile /></SeekerWrapper>} />
          <Route path="/admin-dashboard" element={<AdminWrapper>{/* AdminDashboard */}</AdminWrapper>} />
          <Route path="/supplier-dashboard" element={<SupplierWrapper>{/* SupplierDashboard */}</SupplierWrapper>} />
        </Routes>
        </div>
        {showConsentModal && <ConsentModal onAllow={() => handleConsent('all')} onEssentialsOnly={() => handleConsent('essentials')} />}
      </Router>
    </UserProvider>
  );
}

export default App;

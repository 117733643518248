import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import imageCompression from 'browser-image-compression';
import Button from '../buttons/Button';
import './UploadCoverPhoto.css';

const UploadCoverPhoto = ({ onUploadComplete, imageUrl, theUserName }) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [thumbnail, setThumbnail] = useState(imageUrl || null);
  const [error, setError] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    setThumbnail(imageUrl); // Sync with imageUrl
  }, [imageUrl]);

  const handleClick = () => {
    setError('');
    inputRef.current.click(); // Trigger file input
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      setUploading(true);
      simulateProgress();

      const compressedFile = await compressImage(file);
      await uploadToAWS(compressedFile);
    } catch (error) {
      console.error('Error uploading image:', error);
      setError('Image upload failed. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  const simulateProgress = () => {
    let currentProgress = 0;
    const interval = setInterval(() => {
      currentProgress += 10;
      setProgress(currentProgress);
      if (currentProgress >= 100) clearInterval(interval);
    }, 200);
  };

  const compressImage = async (imageFile) => {
    const options = {
      maxSizeMB: 1, // Ensure the size is limited to 1MB
      useWebWorker: true, // Enable WebWorker for performance
      maxWidthOrHeight: Infinity, // Allow any resolution
      initialQuality: 0.8, // Initial compression quality (0-1)
      alwaysKeepResolution: true, // Maintain original resolution regardless of size
    };
    return await imageCompression(imageFile, options);
  };

  const uploadToAWS = async (file) => {
    const data = new FormData();
    data.append('image', file);
    data.append('theUserName', theUserName);

    const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/seeker/upload-cover-image`, {
      method: 'POST',
      body: data,
    });

    if (!response.ok) throw new Error('Upload failed');
    const result = await response.json();

    setThumbnail(result.url);
    onUploadComplete(result.url);
  };

  const handleDeleteImage = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/seeker/delete-cover-image`, {
        method: 'DELETE',
        body: JSON.stringify({ theUserName }),
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) throw new Error('Failed to delete image');
      setThumbnail(null);
      onUploadComplete(null);
    } catch (error) {
      console.error('Error deleting image:', error);
      setError('Failed to delete the image.');
    }
  };

  return (
    <div className="upload-cover-container">
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />

      <div className="cover-thumbnail-container" onClick={handleClick}>
        {uploading ? (
          <div className="upload-progress">
            <p>{`Uploading... ${progress}%`}</p>
          </div>
        ) : thumbnail ? (
          <>
            <img src={thumbnail} alt="Cover Thumbnail" className="cover-thumbnail" />
            <div className="cover-overlay">
              <p>Max 1MB</p>
              <Button
                variant="tertiary"
                label="Remove Cover"
                onClick={handleDeleteImage}
                size="auto"
                className="remove-button"
              />
              {error && <small className="image-error">{error}</small>}
            </div>
          </>
        ) : (
          <div className="cover-overlay">
            <p>Select Cover Photo</p>
            <p>Max 1MB</p>
          </div>
        )}
      </div>
    </div>
  );
};

UploadCoverPhoto.propTypes = {
  onUploadComplete: PropTypes.func.isRequired,
  imageUrl: PropTypes.string,
  theUserName: PropTypes.string.isRequired,
};

export default UploadCoverPhoto;

import React, { useState, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import CalendarIcon from '../../assets/icons/calendar-icon.svg';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import './DateInputBox.css';

const DateInputBox = ({ value, onChange, size = 'medium' }) => {
  const [selectedDate, setSelectedDate] = useState(value ? dayjs(value) : null);
  const [inputValue, setInputValue] = useState(value ? dayjs(value).format('DD/MM/YYYY') : '');
  const [openCalendar, setOpenCalendar] = useState(false);

  // Set locale globally to ensure correct formatting (dd/mm/yyyy)
  dayjs.locale('en-gb');

  useEffect(() => {
    if (value) {
      const date = dayjs(value);
      setSelectedDate(date);
      setInputValue(date.format('DD/MM/YYYY'));
    }
  }, [value]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    const dateParts = value.split('/');
    if (dateParts.length === 3) {
      const day = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1;
      const year = parseInt(dateParts[2], 10);

      if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
        const newDate = dayjs(new Date(year, month, day));
        if (newDate.isValid()) {
          setSelectedDate(newDate);
          onChange(newDate.toISOString()); // Send ISO string to the backend
        }
      }
    }
  };

  const handleInputFocus = () => setOpenCalendar(true);
  const handleCalendarClose = () => setOpenCalendar(false);

  return (
    <div className={`date-input-container date-input-${size}`}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <div className="date-input-box" onClick={handleInputFocus}>
          <img
            src={CalendarIcon}
            alt="Calendar icon"
            className="date-input-icon"
            onClick={handleInputFocus}
          />
          <DatePicker
            value={selectedDate}
            onChange={(newValue) => {
              setSelectedDate(newValue);
              setInputValue(dayjs(newValue).format('DD/MM/YYYY'));
              onChange(newValue.toISOString()); // Send ISO string to the backend
            }}
            inputFormat="DD/MM/YYYY"
            open={openCalendar}
            onClose={handleCalendarClose}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="dd/mm/yyyy"
                fullWidth
                value={inputValue}
                onChange={handleInputChange}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
                onFocus={handleInputFocus}
              />
            )}
          />
        </div>
      </LocalizationProvider>
    </div>
  );
};

export default DateInputBox;

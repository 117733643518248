import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InputBox from '../components/inputBox/InputBox';
import PasswordBox from '../components/inputBox/PasswordBox';
import Button from '../components/buttons/Button';
import InputBoxWithButton from '../components/inputBox/InputBoxWithButton';
import Checkbox from '../components/selectors/Checkbox';
import './Register.css';
import natureImage from '../assets/images/nature.jpg';
import checkIcon from '../assets/icons/search-white.svg';
import usernameIcon from '../assets/icons/username.svg';
import emailIcon from '../assets/icons/email.svg';
import passwordIcon from '../assets/icons/password.svg';
import axios from 'axios';

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [passwordValid, setPasswordValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);

  const [supplier, setSupplier] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [isUsernameUnique, setIsUsernameUnique] = useState(null);
  const [isCheckingUsername, setIsCheckingUsername] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otpTimer, setOtpTimer] = useState(180);
  const [canResendOtp, setCanResendOtp] = useState(false);

  useEffect(() => {
    if (otpSent && otpTimer > 0) {
      const timer = setInterval(() => {
        setOtpTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer); // Clear timer on unmount
    } else if (otpTimer === 0) {
      setCanResendOtp(true);
    }
  }, [otpSent, otpTimer]);
  

  const checkUsernameUnique = async () => {
    setIsCheckingUsername(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/seeker/check-username`, { username });
      setIsUsernameUnique(response.data.isUnique);
      setIsCheckingUsername(false);
    } catch (error) {
      setErrorMessage('Error checking username uniqueness.');
      setIsCheckingUsername(false);
    }
  };

  const isFormValid =
    username &&
    emailValid &&
    passwordValid &&
    passwordMatch &&
    acceptedTerms &&
    !isSubmitting &&
    isUsernameUnique;

  const handlePasswordChange = (value) => {
    setPassword(value);
    validatePassword(value);
    if (confirmPassword && value !== confirmPassword) {
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
    }
  };

  const handleConfirmPasswordChange = (value) => {
    setConfirmPassword(value);
    if (value !== password) {
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
    }
  };

  const handleEmailChange = (value) => {
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setEmailValid(false);
    } else {
      setEmailValid(true);
    }
  };

  const validatePassword = (value) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/;
    setPasswordValid(regex.test(value));
  };

  const handleSubmit = async () => {
  if (isFormValid) {
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/seeker/register`,
        {
          username,
          email,
          password,
          supplier,
        }
      );

      if (response.status === 200) {
        setOtpSent(true);
      } else {
        setErrorMessage(response.data.message || 'Registration failed. Please try again.');
      }
    } catch (error) {
      console.error('Error during registration:', error);
      setErrorMessage(error.response?.data?.message || 'An unexpected error occurred.');
    } finally {
      setIsSubmitting(false);
    }
  }
};

const handleResendOtp = async () => {
  setCanResendOtp(false);
  setOtpTimer(180); // Reset timer to 3 minutes
  setErrorMessage(''); // Clear any previous errors
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/seeker/resend-otp`,
      { email }
    );
    if (response.data.success) {
      alert('New OTP sent to your email. Please check your spam folder as well.');
    } else {
      setErrorMessage(response.data.message || 'Failed to resend OTP. Please try again.');
    }
  } catch (error) {
    setErrorMessage(
      error.response?.data?.message || 'An unexpected error occurred while resending OTP.'
    );
  }
};


const handleOtpSubmit = async () => {
  if (!otp) {
    setErrorMessage('Please enter the OTP.');
    return;
  }

  setIsSubmitting(true);
  setErrorMessage('');

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/seeker/verify-otp`,
      { email, otp }
    );

    if (response.data.success) {
      alert('OTP verified. Registration successful.');
      navigate('/login');
    } else {
      setErrorMessage(response.data.message || 'Invalid OTP. Please try again.');
    }
  } catch (error) {

    setErrorMessage(
      error.response?.data?.message || 'An unexpected error occurred during OTP verification.'
    );
  } finally {
    setIsSubmitting(false);
  }
};


  return (
    <div className="register-page" style={{ backgroundImage: `url(${natureImage})` }}>
      <div className="register-box flex-column-md-start-start">
      {!otpSent && !showOtpInput ? (

          <>
            <h2 className="register-title">Register</h2>
            {/* Optional checkbox for supplier */}
            <Checkbox
              label="I am a registered supplier"
              checked={supplier}
              onChange={setSupplier}
            />
            <div className="fill-container flex-column-md-start-start">
              <InputBoxWithButton
                placeholder="Username"
                size="large"
                icon={checkIcon}
                leftIcon={usernameIcon}
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                  setIsUsernameUnique(null); // Reset username uniqueness status on change
                }}
                onButtonClick={checkUsernameUnique}
              />
              {isCheckingUsername && <p className="info-text">Checking username...</p>}
              {isUsernameUnique === false && <p className="error-text">Username is already taken.</p>}
              {isUsernameUnique === true && <p className="success-text">Username is available.</p>}
              <InputBox
                placeholder="Email"
                size="large"
                icon={emailIcon}
                value={email}
                onChange={(e) => handleEmailChange(e.target.value)}
              />
              <PasswordBox
                placeholder="Password"
                size="large"
                icon={passwordIcon}
                value={password}
                onChange={(e) => handlePasswordChange(e.target.value)}
              />
              <div className="flex-column-sm-start-start fill-container">
                <PasswordBox
                  placeholder="Retype Password"
                  size="large"
                  icon={passwordIcon}
                  value={confirmPassword}
                  onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                />
                {errorMessage && (
                  <p className="register-password-instruction error">{errorMessage}</p>
                )}
                {!errorMessage && (
                  <p className="register-password-instruction">
                    Password must be at least 8 characters, with uppercase, lowercase, numbers, and special characters.
                  </p>
                )}
              </div>
              {/* Mandatory checkbox for terms and privacy policy */}
              <Checkbox
                label={
                  <>
                    I have read and accepted all the{' '}
                    <a href="/terms" className="terms-link">terms</a> and{' '}
                    <a href="/privacy" className="privacy-link">privacy policy</a>.
                  </>
                }
                checked={acceptedTerms}
                onChange={setAcceptedTerms}
                required={true} // Make this mandatory
              />
              <Button
                variant={isSubmitting ? 'submit' : 'cta'}
                label={isSubmitting ? 'Submitting...' : 'Submit'}
                size="fullWidth"
                onClick={handleSubmit}
                disabled={!isFormValid || isUsernameUnique === false || isCheckingUsername} // Disable if username is not unique or still checking
                loading={isSubmitting}
              />
              <div className='register-verify-login flex-row-sm-space-between-center '>
                <Button
                  variant="tertiary"
                  label="Verify Email"
                  size="small"
                  onClick={() => setShowOtpInput(true)}
                />
                <p className="register-already-account">
                  Already have an account? <a href="/login">Login</a>
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <h2 className="register-title">Enter OTP</h2>
            <p>You should have received an OTP in your email. Please enter the OTP below.</p>
            {otpSent && (
              <>
                <p className="info-text" style={{ color: 'red' }}>
                  OTP expires in: {Math.floor(otpTimer / 60)}:{otpTimer % 60 < 10 ? `0${otpTimer % 60}` : otpTimer % 60} minutes
                </p>
                {canResendOtp ? (
                  <Button
                    variant="tertiary"
                    label="Resend OTP"
                    size="small"
                    onClick={handleResendOtp}
                  />
                ) : (
                  <p className="info-text">You can request a new OTP after the countdown ends.</p>
                )}
              </>
            )}
            <InputBox
              placeholder="Enter OTP"
              size="large"
              value={otp}
              onChange={(e) => {
                setOtp(e.target.value);
                setErrorMessage('');
              }}
            />
            {errorMessage && (
              <p className="error-text">{errorMessage}</p>
            )}
            <Button
              variant="submit"
              label={isSubmitting ? 'Submitting...' : 'Submit OTP'}
              size="fullWidth"
              onClick={handleOtpSubmit}
              disabled={isSubmitting || !otp}
            />

          </>
        )}
      </div>
    </div>
  );
};

export default Register;

import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import InputBox from '../components/inputBox/InputBox';
import PasswordBox from '../components/inputBox/PasswordBox';
import Button from '../components/buttons/Button';
import { UserContext } from '../components/userData/UserContext';
import './Login.css';
import backgroundImage from '../assets/images/login-bg.jpg';
import emailIcon from '../assets/icons/email.svg';
import passwordIcon from '../assets/icons/password.svg';

const Login = () => {
  const [emailOrUsername, setEmailOrUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleEmailOrUsernameChange = (value) => {
    setEmailOrUsername(value);
    setErrorMessage('');
  };

  const handleSubmit = async () => {
    if (emailOrUsername && password) {
      setIsSubmitting(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/seeker/login`,
          { emailOrUsername, password },
          { withCredentials: true }
        );
        
        if (response.status === 200) {
          const { token, userInfo } = response.data;

          // Store user info and token in cookies
          Cookies.set('token', token, { path: '/', sameSite: 'Strict', secure: true });
          Cookies.set('userInfo', JSON.stringify(userInfo), { path: '/', sameSite: 'Strict', secure: true });

          // Update UserContext
          setUser(userInfo);

          // Navigate to SeekerDashboard
          navigate('/seeker-dashboard');
        }
      } catch (error) {
        setErrorMessage(error.response?.data?.message || 'Login failed. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  // Handle Enter key press for submitting the form
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className="login-page" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="login-box flex-column-md-start-start">
        <h2 className="login-title">Login</h2>

        <div className="fill-container flex-column-md-center-center">
        <InputBox
          placeholder="Username or Email"
          size="large"
          icon={emailIcon}
          value={emailOrUsername}
          onChange={(e) => handleEmailOrUsernameChange(e.target.value)}
          onKeyDown={handleKeyDown}
        />

        <PasswordBox
          placeholder="Password"
          size="large"
          icon={passwordIcon}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleKeyDown}
        />


          {errorMessage && <p className="login-error-text">{errorMessage}</p>}

          <Button
            variant={isSubmitting ? 'submit' : 'cta'}
            label={isSubmitting ? 'Logging in...' : 'Login'}
            size="fullWidth"
            onClick={handleSubmit}
            disabled={isSubmitting || !emailOrUsername || !password}
            loading={isSubmitting}
          />

          <Button variant="tertiary" onClick={() => navigate('/forgot-password')} label="Forgot Password?" size="large" className="login-forgot-password" />

          <p className="login-already-account">
            Don't have an account? <a href="/register">Register</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import InputBox from '../components/inputBox/InputBox';
import PasswordBox from '../components/inputBox/PasswordBox';
import Button from '../components/buttons/Button';
import backIcon from '../assets/icons/back.svg';
import './ForgotPassword.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [username, setUsername] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [activeTab, setActiveTab] = useState('request');
  const [message, setMessage] = useState('');
  const [timer, setTimer] = useState(180);
  const [showTimer, setShowTimer] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordMatching, setIsPasswordMatching] = useState(false);
  const navigate = useNavigate();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleEmailChange = (value) => {
    setEmail(value);
    setIsEmailValid(emailRegex.test(value));
  };

  const handleNewPasswordChange = (value) => {
    setNewPassword(value);
    setIsPasswordMatching(value === retypePassword);
  };

  const handleRetypePasswordChange = (value) => {
    setRetypePassword(value);
    setIsPasswordMatching(newPassword === value);
  };

  const handleRequestOtp = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/password/request-reset`,
        { email }
      );
      setMessage(response.data.message);
      setShowTimer(true);
      startTimer();
    } catch (error) {
      setMessage(error.response?.data?.message || 'Failed to send OTP.');
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/password/verify-otp`,
        { email, username, otp }
      );
      if (response.status === 200) {
        setActiveTab('reset');
        setMessage(''); // Clear message on switch to reset tab
      }
    } catch (error) {
      setMessage(error.response?.data?.message || 'Invalid OTP or details.');
    }
  };

  const handleResetPassword = async () => {
    if (newPassword !== retypePassword) {
      setMessage('Passwords do not match.');
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/password/reset-password`, {
        email,
        otp,
        newPassword,
      });
      alert('Password reset successful!');
      navigate('/login');
    } catch (error) {
      setMessage(error.response?.data?.message || 'Password reset failed.');
    }
  };

  const startTimer = () => {
    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          clearInterval(interval);
          setShowTimer(false);
        }
        return prev - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    if (activeTab !== 'request') {
      setMessage('');
    }
  }, [activeTab]);

  return (
    <div className="forgot-password-page">
      <div className="forgot-password-box">
        <div className="forgot-password-header">
          {activeTab !== 'request' && (
            <img
              src={backIcon}
              alt="Back"
              className="back-icon"
              onClick={() => setActiveTab('request')}
            />
          )}
          <h2 className="forgot-password-title">Forgot Password</h2>
        </div>

        {activeTab === 'request' && (
          <>
            <InputBox
              placeholder="Enter your email"
              size="large"
              value={email}
              onChange={(e) => handleEmailChange(e.target.value)}
            />
            <div className="button-group">
              <Button
                variant="primary"
                label="Request OTP"
                size="medium"
                onClick={handleRequestOtp}
                disabled={!isEmailValid || showTimer}
              />
              {showTimer && timer > 0 && (
                <span className="countdown-text">{`${timer}s`}</span>
              )}
              <Button
                variant="tertiary"
                label="I have the OTP"
                size="medium"
                onClick={() => setActiveTab('otp')}
              />
            </div>
            {message && <p className="message-text">{message}</p>}
          </>
        )}

        {activeTab === 'otp' && (
          <>
            <InputBox
              placeholder="Enter Email"
              size="large"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <InputBox
              placeholder="Enter Username"
              size="large"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <InputBox
              placeholder="Enter OTP"
              size="large"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <Button
              variant="primary"
              label="Verify OTP"
              size="fullWidth"
              onClick={handleVerifyOtp}
            />
          </>
        )}

        {activeTab === 'reset' && (
          <>
            <p className="password-instruction">
              Password must be at least 8 characters long and contain a mix of letters, numbers, and special characters.
            </p>
            <PasswordBox
              placeholder="New Password"
              size="large"
              value={newPassword}
              onChange={(e) => handleNewPasswordChange(e.target.value)}
            />
            <PasswordBox
              placeholder="Retype Password"
              size="large"
              value={retypePassword}
              onChange={(e) => handleRetypePasswordChange(e.target.value)}
            />
            <Button
              variant="primary"
              label="Reset Password"
              size="fullWidth"
              onClick={handleResetPassword}
              disabled={!isPasswordMatching}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import InputBox from '../components/inputBox/InputBox';
import PasswordBox from '../components/inputBox/PasswordBox';
import Button from '../components/buttons/Button';
import './AccountSettings.css';

const AccountSettings = () => {
  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [trackers, setTrackers] = useState({
    essential: true,
    fullTracking: false,
    doNotTrack: false,
    receiveEmails: false,
  });
  const [message, setMessage] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
  const [isPasswordStep, setIsPasswordStep] = useState(false);
  const [passwordError, setPasswordError] = useState('');

  

  const passwordInstruction = 'Password must be at least 8 characters, contain uppercase, lowercase, and a number.';

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    return regex.test(password);
  };


  const handleVerifyCredentials = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/seeker/verify-credentials`,
        { email, currentPassword }
      );
      if (response.status === 200) {
        setIsPasswordStep(true);
        setMessage('');
      }
    } catch (error) {
      setMessage(error.response?.data?.message || 'Invalid email or password.');
    }
  };

  const handleTrackingOptionChange = async (option) => {
    try {
      const updatedTrackers = {
        essential: option === 'essential',
        fullTracking: option === 'fullTracking',
        doNotTrack: option === 'doNotTrack',
      };
  
      setTrackers(updatedTrackers);
  
      await axios.put(`${process.env.REACT_APP_SERVER_BASE_URL}/api/seeker/tracking-preference`, {
        email,
        trackingOption: option,
      });
  
      alert('Tracking preferences updated successfully.');
    } catch (error) {
      console.error('Error updating tracking preferences:', error);
      setMessage('Failed to update tracking preferences.');
    }
  };
  

  const handleReceiveEmailsToggle = async () => {
    try {
      const newStatus = !trackers.receiveEmails;
      setTrackers((prev) => ({ ...prev, receiveEmails: newStatus }));
  
      await axios.put(`${process.env.REACT_APP_SERVER_BASE_URL}/api/seeker/newsletter-preference`, {
        email,
        receiveEmails: newStatus,
      });
  
      alert('Newsletter preference updated successfully.');
    } catch (error) {
      console.error('Error updating newsletter preference:', error);
      setMessage('Failed to update newsletter preference.');
    }
  };  
  
  const handleFinalPasswordChange = async () => {
    if (newPassword !== retypePassword) {
      setPasswordError('Passwords do not match.');
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/seeker/change-password`, {
        email,
        newPassword,
      });
      alert('Password reset successful!');
      setIsChangePasswordVisible(false);
    } catch (error) {
      setPasswordError(error.response?.data?.message || 'Password reset failed.');
    }
  };

  useEffect(() => {
    setIsPasswordValid(validatePassword(newPassword));
  }, [newPassword]);
  

  const handleDeleteAccount = async () => {
    if (!window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      return;
    }
    try {
      const response = await axios.delete(`${process.env.REACT_APP_SERVER_BASE_URL}/api/seeker/delete-account`, {
        data: { email, password: currentPassword },
      });
      alert(response.data.message);
    } catch (error) {
      setMessage(error.response?.data?.message || 'Failed to delete account.');
    }
  };

  return (
    <div className="account-settings-page">
      <div className="account-settings-container flex-column-lg-start-start ">
        <h4>Account Settings</h4>
        <div className="settings-section flex-row-lg-center-center">
            <h6>Tracking Preferences</h6>
            <div className="radio-group flex-row-md-start-start">
                <label className="disabled-text">
                <input
                    type="radio"
                    name="trackingOption"
                    value="essential"
                    checked={trackers.essential}
                    disabled
                />
                Partial Personalization
                </label>

                <label>
                <input
                    type="radio"
                    name="trackingOption"
                    value="fullTracking"
                    checked={trackers.fullTracking}
                    onChange={() => handleTrackingOptionChange('fullTracking')}
                />
                Fully Personalised
                </label>

                <label>
                <input
                    type="radio"
                    name="trackingOption"
                    value="doNotTrack"
                    checked={trackers.doNotTrack}
                    onChange={() => handleTrackingOptionChange('doNotTrack')}
                />
                Do Not Personalize
                </label>
            </div>
            </div>
            <hr />

            <div className="settings-section flex-row-lg-space-between-start">
                <h6>Newsletter Subscription</h6>
                <div className="toggle-group flex-row-md-start-start">
                    <label className="toggle-label">
                    <input
                        type="checkbox"
                        checked={trackers.receiveEmails}
                        onChange={handleReceiveEmailsToggle}
                    />
                    Receive New Deals and Offers
                    </label>
                </div>
                </div>

            <hr/>

        <div className="settings-section flex-row-sm-space-between-center">
          <h6>Change Password</h6>
          {!isChangePasswordVisible ? (
            <Button
              label="Change Password"
              variant="secondary"
              size="large"
              onClick={() => setIsChangePasswordVisible(true)}
            />
          ) : (
            <>
              {!isPasswordStep ? (
                <>
                <div className='flex-column-md-end-end'>
                <div className='flex-row-sm-center-center'>
                  <InputBox
                    placeholder="Enter your email"
                    size="large"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <PasswordBox
                    placeholder="Enter Current Password"
                    size="large"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                  </div>
                  <div className="account-button-group flex-row-md-end-end">
                    <Button
                      label="Cancel"
                      variant="tertiary"
                      size="large"
                      onClick={() => setIsChangePasswordVisible(false)}
                    />
                    <Button
                      label="Next"
                      variant="primary"
                      size="large"
                      onClick={handleVerifyCredentials}
                    />
                  </div>
                  {message && <p className="error-message">{message}</p>}
                  </div>
                </>
              ) : (
                
                <>
                <div className='flex-column-md-end-end'>
                  <div className="flex-row-sm-center-center">
                    <PasswordBox
                        placeholder="New Password"
                        size="large"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <PasswordBox
                        placeholder="Retype New Password"
                        size="large"
                        value={retypePassword}
                        onChange={(e) => setRetypePassword(e.target.value)}
                    />
                  </div>
                  <div className="account-button-group flex-row-lg-end-end">
                    <Button
                      label="Cancel"
                      variant="tertiary"
                      size="large"
                      onClick={() => setIsChangePasswordVisible(false)}
                    />
                    <Button
                      label="Done"
                      variant="primary"
                      size="large"
                      onClick={handleFinalPasswordChange}
                      disabled={!isPasswordValid}
                    />
                  </div>
                  <p className="password-instruction">{passwordInstruction}</p>
                  {passwordError && <p className="error-message">{passwordError}</p>}
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <hr/>
        <div className="settings-section flex-row-lg-space-between-center">
          <h6>Delete Account</h6>
          <Button
            label="Delete Account"
            onClick={handleDeleteAccount}
            variant="tertiary"
            size="large"
          />
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;

import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../components/userData/UserContext';
import axios from 'axios';
import './SeekerProfile.css';
import { Link } from 'react-router-dom';
import editIcon from '../assets/icons/edit.svg';

const SeekerProfile = () => {
  const { user } = useContext(UserContext);

  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/seeker/get-profile/${user.username}`
        );
        setProfileData(response.data);
      } catch (error) {
        console.error('Error fetching profile:', error);
        setError('Failed to load profile.');
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [user.username]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="seeker-profile-container">
      {/* Cover Photo */}
      <div className="cover-photo-container">
        <img
          src={profileData?.coverPhoto || ''}
          alt="Cover"
          className="cover-photo"
        />
      </div>

      <div className="profile-content">
        {/* Profile Photo */}
        <div className="profile-photo-container">
          <img
            src={profileData?.profilePhoto || ''}
            alt="Profile"
            className={`profile-photo ${
              profileData?.profilePhoto ? '' : 'placeholder'
            }`}
          />
        </div>
        <div className="flex-row-lg-space-between-center profile-edit-button">
          <div className="profile-info">
            <h2 className="username">{profileData?.username}</h2>
            <p className="full-name">
              {profileData?.firstName} {profileData?.lastName}
            </p>
          </div>
          
          <Link to="/seeker-profile-edit" className="edit-icon-container">
            <img src={editIcon} alt="Edit Profile" className="edit-icon" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SeekerProfile;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './PasswordBox.css';
import eyeOpenIcon from '../../assets/icons/eye-open.svg';
import eyeCloseIcon from '../../assets/icons/eye-close.svg';

const PasswordBox = ({ placeholder, size = 'medium', icon, value, onChange, onKeyDown }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const togglePasswordVisibility = () => setIsPasswordVisible(!isPasswordVisible);

  const inputClasses = `
    unique-input-box 
    unique-input-${size} 
    ${isFocused ? 'unique-input-focused' : ''} 
    ${icon ? 'unique-input-with-icon' : ''}
  `;

  return (
    <div className="unique-input-container">
      {icon && <img src={icon} alt="icon" className="unique-input-icon" />}
      <input
        type={isPasswordVisible ? 'text' : 'password'}
        className={inputClasses}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={onKeyDown}
        style={{ paddingRight: '48px' }}
      />
      <img
        src={isPasswordVisible ? eyeOpenIcon : eyeCloseIcon}
        alt="Toggle password visibility"
        className="unique-toggle-password-icon"
        onClick={togglePasswordVisibility}
      />
    </div>
  );
};

PasswordBox.propTypes = {
  placeholder: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  icon: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
};

export default PasswordBox;

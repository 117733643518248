import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './MobileInputBox.css';

const MobileInputBox = ({ icon }) => {
  const [countryCode, setCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleCountryCodeChange = (e) => {
    const value = e.target.value;
    // Allow only numbers and +, and limit the length to 4
    if (/^\+?\d{0,3}$/.test(value)) {
      setCountryCode(value);
    }
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    // Only allow numeric input for phone number
    if (/^\d*$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  return (
    <div className="unique-mobile-input-container flex-row-md-center-center"> {/* Correct placement */}
      {icon && <img src={icon} alt="icon" className="unique-mobile-input-icon" />}
      <div className="unique-mobile-code-phone-container">
        <input
          type="text"
          className="unique-mobile-country-code"
          placeholder="+00"
          value={countryCode}
          onChange={handleCountryCodeChange}
        />
        <div className="unique-vertical-line"></div>
        <input
          type="text"
          className="unique-mobile-phone-number"
          placeholder="Phone"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
        />
      </div>
    </div>
  );
};

MobileInputBox.propTypes = {
  icon: PropTypes.string,
};

export default MobileInputBox;

import React from 'react';
import './SeekerDashboard.css';

const SeekerDashboard = () => {
  return (
    <div className="dashboard-container">
      <h1>Welcome to the Seeker Dashboard</h1>
      <div className="dashboard-content">
        <p>This is a dummy page for the Seeker Dashboard. Content will be added later.</p>
      </div>
    </div>
  );
};

export default SeekerDashboard;

export const trackEssentialsOnly = () => {
  fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/trackRoute/track-essentials`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      credentials: 'include',
  }).then((response) => {
      if (!response.ok) {
          throw new Error('Failed to track essentials');
      }
      console.log('Essentials tracking successful');
  }).catch((error) => {
      console.error('Error during tracking essentials:', error);
  });
};

// Function to capture and send full tracking data
export const trackUserData = () => {
    const pagesVisited = [];
    const durationPerPage = [];
    let startTime = Date.now();

    const trackPageVisit = (page) => {
        pagesVisited.push(page);
        const duration = (Date.now() - startTime) / 1000;
        durationPerPage.push(duration);
        startTime = Date.now();
    };

    window.onbeforeunload = () => {
        const currentPage = window.location.href;
        trackPageVisit(currentPage);

        fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/trackRoute/track`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                pagesVisited,
                durationPerPage,
            }),
        });
    };
};

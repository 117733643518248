import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import imageCompression from 'browser-image-compression';
import Button from '../buttons/Button';
import './UploadProfilePhoto.css';

const UploadProfilePhoto = ({ onUploadComplete, imageUrl, imageSize, theUserName }) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [thumbnail, setThumbnail] = useState(imageUrl || null);
  const [error, setError] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    setThumbnail(imageUrl); // Update thumbnail when imageUrl changes
  }, [imageUrl]);

  const handleClick = () => {
    setError('');
    inputRef.current.click(); // Trigger file input dialog
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size > 2 * 1024 * 1024) {
      setError('File size exceeds 2MB.');
      return;
    }

    try {
      setUploading(true);
      simulateProgress();

      const compressedFile = await compressImage(file);
      await uploadToAWS(compressedFile);
    } catch (error) {
      console.error('Error uploading image:', error);
      setError('Image upload failed. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  const simulateProgress = () => {
    let currentProgress = 0;
    const interval = setInterval(() => {
      currentProgress += 10;
      setProgress(currentProgress);
      if (currentProgress >= 100) clearInterval(interval);
    }, 200);
  };

  const compressImage = async (imageFile) => {
    const options = { maxSizeMB: 1, maxWidthOrHeight: 800, useWebWorker: true };
    return await imageCompression(imageFile, options);
  };

  const uploadToAWS = async (file) => {
    const data = new FormData();
    data.append('image', file);
    data.append('theUserName', theUserName); // Use the username

    const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/seeker/upload-profile-image`, {
      method: 'POST',
      body: data,
    });

    if (!response.ok) throw new Error('Upload failed');
    const result = await response.json();

    setThumbnail(result.url); // Update thumbnail locally
    onUploadComplete(result.url); // Notify parent of the new URL
  };

  const handleDeleteImage = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/api/seeker/delete-profile-image`, {
        method: 'DELETE',
        body: JSON.stringify({ theUserName }), // Username required
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) throw new Error('Failed to delete image');
      setThumbnail(null); // Clear the thumbnail locally
      onUploadComplete(null); // Notify parent that the photo is removed
    } catch (error) {
      console.error('Error deleting image:', error);
      setError('Failed to delete the image.');
    }
  };

  return (
    <div className="upload-photo-container">
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />

      <div className="thumbnail-container" onClick={handleClick}>
        {uploading ? (
          <div className="upload-progress">
            <p>{`Uploading... ${progress}%`}</p>
          </div>
        ) : thumbnail ? (
          <>
            <img src={thumbnail} alt="Uploaded Thumbnail" className="thumbnail" />
            <div className="upload-overlay">
              <p>{imageSize}</p>
            </div>
          </>
        ) : (
          <div className="upload-overlay">
            <p>Select Photo</p>
            <p>{imageSize}</p>
          </div>
        )}
      </div>

      {thumbnail && (
        <Button
          variant="tertiary"
          label="Remove Photo"
          onClick={handleDeleteImage}
          size="auto"
          className="remove-button"
        />
      )}

      {error && <small className="image-error">{error}</small>}
    </div>
  );
};

UploadProfilePhoto.propTypes = {
  onUploadComplete: PropTypes.func.isRequired,
  imageUrl: PropTypes.string,
  imageSize: PropTypes.string,
  theUserName: PropTypes.string.isRequired,
};

export default UploadProfilePhoto;

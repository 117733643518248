import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './InputBoxWithButton.css';

const InputBoxWithButton = ({ placeholder, size = 'medium', icon, leftIcon, value, onChange, onButtonClick }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const inputClasses = `
    custom-input-box 
    custom-input-${size} 
    ${isFocused ? 'custom-input-focused' : ''} 
    ${leftIcon ? 'custom-input-with-left-icon' : ''}
  `;

  return (
    <div className="custom-input-container">
      {leftIcon && <img src={leftIcon} alt="left icon" className="custom-input-left-icon" />}
      <input
        type="text"
        className={inputClasses}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <button className="custom-input-button" onClick={onButtonClick}>
        {icon && <img src={icon} alt="icon" className="custom-input-icon" />}
      </button>
    </div>
  );
};

InputBoxWithButton.propTypes = {
  placeholder: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  icon: PropTypes.string.isRequired,
  leftIcon: PropTypes.string,
  value: PropTypes.string.isRequired, 
  onChange: PropTypes.func.isRequired, 
  onButtonClick: PropTypes.func.isRequired,
};

export default InputBoxWithButton;

import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  return (
    <div className="main-content fill-container">
      <section className="terms-container">
        <h1>Terms and Conditions</h1>
        <p>
          Welcome to <strong>Rebrid</strong>, a next-generation, AI-powered leisure activity marketplace. These terms 
          and conditions (“Terms”) govern the access to and use of our platform, services, and tools by all users, 
          including Seekers, Supplier, and Rebrid Admins. By continuing to use Rebrid, users agree to be legally 
          bound by these Terms. If you do not accept any part of these Terms, you must discontinue your usage of the 
          platform immediately.
        </p>
        <p>
          As a facilitator, Rebrid’s core focus is to connect people with meaningful, unique activities that align 
          with sustainable tourism values. These Terms cover every aspect of how our platform functions, including the 
          responsibilities of each type of user, payment processes, dispute handling, privacy policies, and more. 
          Please read the full document carefully to understand your rights and obligations when using Rebrid.
        </p>

        <h2>1. Introduction & Acceptance of Terms</h2>
        <p>
          Rebrid offers a unique approach to leisure activity discovery by leveraging the power of artificial 
          intelligence. Our platform is designed to act as a <strong>recommendation engine</strong>, helping Seekers 
          explore and plan activities personalized to their preferences, while also offering Suppliers valuable 
          insights on how to enhance their offerings. Rebrid also facilitates features such as cost-sharing among 
          friends and family members, joint planning tools, and targeted advertising opportunities for Suppliers.
        </p>
        <p>
          By accessing Rebrid’s services, users (Seekers, Suppliers, and Admins) confirm their understanding and 
          agreement with these Terms. We reserve the right to amend the Terms periodically to adapt to new legal 
          requirements, feature changes, or operational updates. In case of any significant modifications, users will 
          be notified, and continued usage of the platform will indicate acceptance of the updated terms.
        </p>

        <h2>2. Definitions</h2>
        <p>
          To ensure clarity, the following terms will be referenced throughout this agreement:
        </p>
        <ul>
          <li>
            <strong>Seekers:</strong> Individuals who access Rebrid to browse, plan, and book unique activities or 
            experiences. They may also use co-planning tools with friends and participate in cost-sharing options.
          </li>
          <li>
            <strong>Suppliers:</strong> Businesses or individuals who list their activities and services on the 
            platform. Suppliers can access analytics to monitor and optimize their listings and participate in 
            Rebrid’s targeted advertising program for enhanced visibility.
          </li>
          <li>
            <strong>Rebrid Admin:</strong> The administrative team responsible for maintaining the platform, 
            ensuring smooth user experiences, and facilitating new feature rollouts. Rebrid Admins also monitor 
            compliance with these Terms and have the authority to resolve platform-related issues.
          </li>
          <li>
            <strong>Activities:</strong> Unique experiences or services listed by Suppliers that focus on sustainable 
            tourism, eco-friendly travel, volunteering, or NGO support. Rebrid prioritizes activities that align 
            with these core values.
          </li>
          <li>
            <strong>Platform:</strong> This includes Rebrid’s website, mobile applications, APIs (like Bokun), and 
            any other tools or services offered by Rebrid for the purpose of activity discovery and management.
          </li>
        </ul>

        <h2>3. Platform Role & Functionality</h2>
        <p>
          Rebrid’s primary role is to act as an intermediary and recommendation engine. Our platform connects Seekers 
          with Suppliers, ensuring a seamless booking process without taking direct ownership or responsibility for 
          the activities themselves. Rebrid offers AI-powered suggestions, enabling Seekers to discover activities 
          that align with their preferences and engage in collaborative planning.
        </p>
        <p>
          For Suppliers, Rebrid delivers deep insights through analytics tools, helping them understand trends, 
          optimize listings, and enhance user engagement. Suppliers can also use Rebrid’s advertisement options to 
          increase their visibility on the platform, ensuring better discoverability for their activities.
        </p>
        <p>
          Please note that while Rebrid facilitates bookings and provides recommendations, the actual agreement for 
          the performance of the activity lies between the Seeker and the Supplier. Rebrid is not liable for the 
          quality or outcome of any activities offered on the platform.
        </p>

        <h2>4. User Registration & Account Management</h2>
        <p>
          Users are required to create an account to access Rebrid’s core features. Seekers must provide accurate and 
          up-to-date information during registration, as this information is used for booking confirmations and 
          personalized recommendations. Suppliers are similarly responsible for maintaining accurate listings and 
          managing their profiles effectively to reflect real-time availability and services.
        </p>
        <p>
          Accounts on Rebrid are non-transferable, and users are expected to maintain the confidentiality of their 
          login credentials. Rebrid cannot be held responsible for unauthorized access caused by a user’s negligence 
          in securing their credentials. Any suspected breach should be reported to Rebrid Admins immediately for 
          investigation and resolution.
        </p>
        <p>
          Rebrid reserves the right to suspend or terminate accounts that violate these Terms or engage in fraudulent 
          activities. Additionally, accounts inactive for a prolonged period may be deactivated by the Admins as part 
          of system maintenance. Users will receive notifications prior to any account deactivation.
        </p>
        <h2>5. Evolving Platform & User Feedback</h2>
        <p>
          Rebrid is committed to continuous growth and improvement. Our platform evolves regularly to offer better 
          features, enhance user experiences, and adapt to changing trends in the tourism and leisure space. These 
          updates are made with the intention of keeping the platform relevant, responsive, and beneficial to both 
          Seekers and Suppliers. Users can expect new functionalities, feature improvements, and bug fixes over time.
        </p>
        <p>
          Feedback plays a critical role in this process. We actively encourage users to share their thoughts, ideas, 
          and suggestions through the platform’s <strong>feedback tools</strong>. Open discussions on product 
          development are welcomed, as we strive to create a marketplace that reflects the needs and desires of our 
          community. By submitting feedback, users agree that Rebrid may use their input to improve the platform 
          without any obligation to compensate them.
        </p>
        <p>
          It is important to note that while we aim to incorporate user feedback, Rebrid retains the final authority 
          on implementing changes. We cannot guarantee that all suggestions will be acted upon or incorporated into 
          the platform. Users will be informed about significant updates to the platform through notifications or 
          announcements on the site.
        </p>

        <h2>6. Activity Listings & Rebrid’s Rights</h2>
        <p>
          Rebrid’s focus is on promoting unique, sustainable, and meaningful activities. Suppliers are required to 
          list only those activities that align with the platform’s core values, such as eco-friendly tourism, 
          volunteering opportunities, or cultural experiences. We reserve the right to <strong>reject or remove 
          activities</strong> that do not meet these criteria or that may conflict with our values.
        </p>
        <p>
          Suppliers are responsible for ensuring the accuracy of their listings, including detailed descriptions, 
          pricing, availability, and terms. Listings should be kept up-to-date at all times to avoid discrepancies 
          and ensure a smooth booking experience for Seekers. Any misleading or inaccurate information may result in 
          the removal of the listing and potential account suspension.
        </p>
        <p>
          Rebrid reserves the right to audit listings and make editorial adjustments if necessary, ensuring they align 
          with the platform’s standards. Suppliers are notified of any changes made to their listings and are given 
          the opportunity to review and approve them. Activities that violate local laws, present safety concerns, or 
          do not align with sustainable tourism principles will be removed from the platform immediately.
        </p>

        <h2>7. Payment, Cancellation, and Refunds</h2>
        <p>
          Rebrid facilitates payments through secure third-party gateways to ensure smooth transactions. When Seekers 
          book activities, the payment is collected on behalf of the Supplier. Suppliers are responsible for setting 
          their own pricing policies, including any applicable taxes and fees, which will be communicated clearly at 
          the time of booking.
        </p>
        <p>
          Cancellations and refunds are governed by each Supplier’s individual policies. Seekers are advised to review 
          the cancellation terms carefully before making a booking. In case of cancellations initiated by the Supplier 
          (e.g., due to unforeseen circumstances), Seekers will be notified promptly and provided with appropriate 
          options, such as rescheduling or full refunds.
        </p>
        <p>
          Please note that Rebrid is not liable for payment disputes between Seekers and Suppliers. Any issues related 
          to refunds, cancellations, or service delivery must be resolved directly between the parties involved. In 
          the event of disputes, Rebrid may offer limited support to facilitate communication, but we do not assume 
          responsibility for resolving such matters.
        </p>

        <h2>8. Dispute Resolution & Liability Limitations</h2>
        <p>
          Rebrid serves only as a connector between Seekers and Suppliers, facilitating discovery and bookings of 
          activities. As such, we are not responsible for resolving disputes that arise between users, whether they 
          relate to bookings, payments, or the quality of services rendered. Any personal disputes or legal matters 
          must be addressed directly between the involved parties, outside of Rebrid’s purview.
        </p>
        <p>
          While we strive to ensure that all activities listed on our platform meet certain standards, Rebrid cannot 
          guarantee the quality or safety of the services provided. Users participate in activities at their own 
          discretion and risk. We strongly encourage both Seekers and Suppliers to communicate openly and clarify 
          any doubts before finalizing bookings.
        </p>
        <p>
          To the extent permitted by law, Rebrid disclaims all liability for any indirect, incidental, or consequential 
          damages arising from the use of the platform. This includes, but is not limited to, damages resulting from 
          missed bookings, cancellations, or issues related to payment transactions. Users agree to indemnify and 
          hold Rebrid harmless from any claims, losses, or damages resulting from their use of the platform.
        </p>

        <h2>9. Privacy & Data Handling</h2>
        <p>
          At Rebrid, we value the privacy of our users. We are committed to ensuring that all personal data is handled 
          securely and in compliance with relevant privacy laws. Our platform collects only the necessary information 
          to facilitate bookings, personalize recommendations, and improve user experience. For more details, please 
          refer to our <a href="/privacy">Privacy Policy</a>.
        </p>
        <p>
          When Seekers book activities, certain data, such as contact information, may be shared with Suppliers through 
          the <strong>Bokun API</strong>. This data sharing is essential for activity coordination and ensuring smooth 
          service delivery. However, Rebrid does not sell personal user data to third parties under any circumstances.
        </p>
        <p>
          Users are encouraged to review and manage their privacy settings to control how their data is used. Rebrid 
          also provides transparency about cookies and other tracking technologies used on the platform. Any inquiries 
          related to privacy can be directed to <a href="mailto:support@rebrid.co">support@rebrid.co</a>.
        </p>

        <h2>10. Supplier Advertising & Promotions</h2>
        <p>
          Rebrid offers Suppliers the opportunity to enhance their visibility through targeted advertisements within 
          the platform. These ads are designed to help Suppliers attract more Seekers by showcasing their listings 
          prominently. Advertising slots are available for a fee and are allocated based on relevance, platform usage, 
          and availability.
        </p>
        <p>
          Rebrid reserves the right to review and approve all advertisements before they go live. Ads that do not align 
          with the platform’s values or contain misleading content will be rejected. Suppliers are responsible for 
          ensuring that their advertisements comply with applicable laws and do not infringe on the rights of others.
        </p>
        <p>
          Suppliers can monitor the performance of their ads through analytics tools provided by the platform. 
          Performance reports will offer insights into views, clicks, and conversions, enabling Suppliers to optimize 
          their campaigns effectively.
        </p>

        <h2>11. Intellectual Property</h2>
        <p>
          All content on the Rebrid platform, including the recommendation engine, software, design elements, text, 
          graphics, and trademarks, are the exclusive property of Rebrid or its licensors. Users may not reproduce, 
          distribute, modify, or publicly display any part of the platform without prior written permission from Rebrid.
        </p>
        <p>
          Users who upload or share content on the platform (e.g., reviews or images) grant Rebrid a non-exclusive, 
          royalty-free license to use, reproduce, and display the content for promotional purposes. Users are 
          responsible for ensuring that the content they share does not violate the intellectual property rights of 
          third parties.
        </p>
        <p>
          Unauthorized use of Rebrid’s intellectual property may result in account suspension and legal action. If you 
          believe that your intellectual property rights have been infringed, please contact us immediately at 
          <a href="mailto:support@rebrid.co">support@rebrid.co</a>.
        </p>

        <h2>12. Account Termination & Inactivity</h2>
        <p>
          Users may deactivate their accounts at any time by contacting Rebrid support or using the account settings 
          on the platform. Rebrid also reserves the right to suspend or terminate accounts that violate these Terms 
          or engage in fraudulent activities.
        </p>
        <p>
          In cases where an account remains inactive for an extended period, Rebrid may deactivate the account as part 
          of system maintenance. Users will receive notifications prior to deactivation, with instructions on how to 
          reactivate their accounts if needed.
        </p>
        <p>
          Termination of an account does not absolve users from any outstanding obligations, such as payments owed to 
          Suppliers. Rebrid retains the right to pursue appropriate legal action for unresolved issues.
        </p>

        <h2>13. Governing Law & Dispute Resolution</h2>
        <p>
          These Terms and any disputes arising from them are governed by the laws of the jurisdiction in which Rebrid 
          operates. Users agree to resolve disputes through direct negotiation whenever possible. If a resolution cannot 
          be reached, disputes will be referred to the appropriate legal channels.
        </p>
        <p>
          Rebrid is not responsible for resolving personal disputes between users. Users must handle such matters 
          independently. However, we may provide limited assistance in facilitating communication between parties.
        </p>
        <p>
          In the event of legal proceedings, users agree that the courts of the relevant jurisdiction will have exclusive 
          authority to resolve the dispute. Any legal costs incurred by Rebrid due to a user’s actions may be recovered 
          from the user.
        </p>

        <h2>14. Amendments & Updates to Terms</h2>
        <p>
          Rebrid reserves the right to modify these Terms at any time to reflect changes in the law, new features, or 
          operational adjustments. Users will be notified of significant updates through announcements on the platform 
          or via email.
        </p>
        <p>
          Continued use of the platform following an update constitutes acceptance of the revised Terms. If users do 
          not agree with the changes, they must discontinue their use of the platform immediately.
        </p>

        <h2>15. Contact Information</h2>
        <p>
          For any inquiries, support requests, or complaints, please reach out to us at 
          <a href="mailto:support@rebrid.co">support@rebrid.co</a>. We strive to respond to all inquiries promptly and 
          provide assistance where possible.
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;

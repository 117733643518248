import React from 'react';
import Button from '../components/buttons/Button';
import InputBox from '../components/inputBox/InputBox';
import './Demo.css';
import placeholderIcon from '../assets/icons/placeholder.svg';
import placeholderIconWhite from '../assets/icons/placeholder-white.svg';
import PasswordBox from '../components/inputBox/PasswordBox';
import InputBoxWithButton from '../components/inputBox/InputBoxWithButton';
import MobileInputBox from '../components/inputBox/MobileInputBox';
import DateInputBox from '../components/inputBox/DateInputBox';

const Demo = () => {
  return (
    <div className="demo-container">
      <h1 className="demo-title">Button Component Showcase</h1>

      <hr/>

      {/* CTA Buttons */}
      <div className="button-section flex-column-md-start-start">
        <h6 className="demo-section-title">CTA Button</h6>
        <div className="flex-row-md-center-center">
          <Button variant="cta" label="CTA Small" size="small" />
          <Button variant="cta" label="CTA Medium" size="medium" />
          <Button variant="cta" label="CTA Large" size="large" />
        </div>
        <Button variant="cta" label="Full Width CTA" size="medium" className="button-fullWidth" />
      </div>

      {/* Primary Buttons */}
      <div className="button-section flex-column-md-start-start">
        <h6 className="demo-section-title">Primary Button</h6>
        <div className="flex-row-md-center-center">
          <Button variant="primary" label="Primary Small" size="small" />
          <Button variant="primary" label="Primary Medium" size="medium" />
          <Button variant="primary" label="Primary Large" size="large" />
        </div>
      </div>

      {/* Secondary Buttons */}
      <div className="button-section flex-column-md-start-start">
        <h6 className="demo-section-title">Secondary Button</h6>
        <div className="flex-row-md-center-center">
          <Button variant="secondary" label="Secondary Small" size="small" />
          <Button variant="secondary" label="Secondary Medium" size="medium" />
          <Button variant="secondary" label="Secondary Large" size="large" />
        </div>
      </div>

      {/* Tertiary Buttons */}
      <div className="button-section flex-column-md-start-start">
        <h6 className="demo-section-title">Tertiary Button (Text Only)</h6>
        <Button variant="tertiary" label="Tertiary" size="medium" />
      </div>

      {/* Submit Button with Loading State */}
      <div className="button-section flex-column-md-start-start">
        <h6 className="demo-section-title">Submit Button</h6>
        <div className="flex-row-md-center-center">
          <Button variant="submit" label="Submit" size="medium" loading={false} />
          <Button variant="submit" size="medium" loading={true} />
        </div>
      </div>

      {/* Danger Buttons */}
      <div className="button-section flex-column-md-start-start">
        <h6 className="demo-section-title">Danger Button</h6>
        <Button variant="danger" label="Danger" size="medium" />
      </div>

      {/* Link Buttons */}
      <div className="button-section flex-column-md-start-start">
        <h6 className="demo-section-title">Link Button</h6>
        <Button variant="link" label="Link" size="medium" />
      </div>

      {/* Icon Buttons */}
      <div className="button-section flex-column-md-start-start">
        <h6 className="demo-section-title">Icon Button</h6>
        <div className="flex-row-md-center-center">
          <Button variant="icon-button" icon={placeholderIcon} size="medium" iconButton />
          <Button variant="icon-button" icon={placeholderIcon} size="medium" iconButton className="button-cta" />
        </div>
      </div>

      {/* Disabled Buttons */}
      <div className="button-section flex-column-md-start-start">
        <h6 className="demo-section-title">Disabled Buttons</h6>
        <div className="flex-row-md-center-center">
          <Button variant="primary" label="Disabled Primary" size="medium" disabled />
          <Button variant="secondary" label="Disabled Secondary" size="medium" disabled />
          <Button variant="danger" label="Disabled Danger" size="medium" disabled />
        </div>
      </div>
      <h1 className="demo-title">InputBox Component Showcase</h1>
       {/* Input Boxes */}
       <div className="input-section flex-column-md-start-start">
        <h6 className="demo-section-title">Input Boxes</h6>

        {/* Input Box with Icon - Small */}
        <div className="flex-row-md-center-center">
          <InputBox placeholder="Small Input with Icon" size="small" icon={placeholderIcon} />
        </div>

        {/* Input Box without Icon - Small */}
        <div className="flex-row-md-center-center">
          <InputBox placeholder="Small Input without Icon" size="small" />
        </div>

        {/* Input Box with Icon - Medium */}
        <div className="flex-row-md-center-center">
          <InputBox placeholder="Medium Input with Icon" size="medium" icon={placeholderIcon} />
        </div>

        {/* Input Box without Icon - Medium */}
        <div className="flex-row-md-center-center">
          <InputBox placeholder="Medium Input without Icon" size="medium" />
        </div>

        {/* Input Box with Icon - Large */}
        <div className="flex-row-md-center-center">
          <InputBox placeholder="Large Input with Icon" size="large" icon={placeholderIcon} />
        </div>

        {/* Input Box without Icon - Large */}
        <div className="flex-row-md-center-center">
          <InputBox placeholder="Large Input without Icon" size="large" />
        </div>
      </div>
      <h1 className="demo-title">PasswordBox Component Showcase</h1>
      {/* Password Boxes */}
      <div className="password-section flex-column-md-start-start">
        <h6 className="demo-section-title">Password Boxes</h6>

        {/* Password Box with Icon - Small */}
        <div className="flex-row-md-center-center">
          <PasswordBox placeholder="Small Password with Icon" size="small" icon={placeholderIcon} />
        </div>

        {/* Password Box without Icon - Small */}
        <div className="flex-row-md-center-center">
          <PasswordBox placeholder="Small Password without Icon" size="small" />
        </div>

        {/* Password Box with Icon - Medium */}
        <div className="flex-row-md-center-center">
          <PasswordBox placeholder="Medium Password with Icon" size="medium" icon={placeholderIcon} />
        </div>

        {/* Password Box without Icon - Medium */}
        <div className="flex-row-md-center-center">
          <PasswordBox placeholder="Medium Password without Icon" size="medium" />
        </div>

        {/* Password Box with Icon - Large */}
        <div className="flex-row-md-center-center">
          <PasswordBox placeholder="Large Password with Icon" size="large" icon={placeholderIcon} />
        </div>

        {/* Password Box without Icon - Large */}
        <div className="flex-row-md-center-center">
          <PasswordBox placeholder="Large Password without Icon" size="large" />
        </div>
      </div>

      {/* Input Box with Button (Icon instead of text) */}
      <div className="input-with-button-section flex-column-md-start-start">
        <h6 className="demo-section-title">Input Box with Button</h6>

        {/* Input Box with Icon Button - Small */}
        <div className="flex-row-md-center-center">
          <InputBoxWithButton placeholder="Small Input with Button" size="small" icon={placeholderIconWhite} leftIcon={placeholderIcon}  />
        </div>

        {/* Input Box with Icon Button - Medium */}
        <div className="flex-row-md-center-center">
          <InputBoxWithButton placeholder="Medium Input with Button" size="medium" icon={placeholderIconWhite} leftIcon={placeholderIcon}  />
        </div>

        {/* Input Box with Icon Button - Large */}
        <div className="flex-row-md-center-center">
          <InputBoxWithButton placeholder="Large Input with Button" size="large" icon={placeholderIconWhite} leftIcon={placeholderIcon}  />
        </div>
      </div>

           {/* Mobile Input Box Section */}
           <div className="mobile-input-section flex-column-md-start-start">
        <h6 className="demo-section-title">Mobile Input Box</h6>

        {/* Mobile Input Box with Icon - Small */}
        <div className="flex-row-md-center-center">
          <MobileInputBox icon={placeholderIcon} />
        </div>

        {/* Mobile Input Box without Icon - Medium */}
        <div className="flex-row-md-center-center">
          <MobileInputBox icon={null} />
        </div>

        {/* Mobile Input Box with Icon - Large */}
        <div className="flex-row-md-center-center">
          <MobileInputBox icon={placeholderIcon} />
        </div>
      </div>

        {/* Date Picker Section */}
        <div className="date-picker-section flex-column-md-start-start">
        <h6 className="demo-section-title">Date Picker</h6>
        
        {/* Date Picker Input Box - Small */}
        <div className="flex-row-md-center-center">
            <DateInputBox size="small" />
        </div>

        {/* Date Picker Input Box - Medium */}
        <div className="flex-row-md-center-center">
            <DateInputBox size="medium" /> {/* Default size */}
        </div>

        {/* Date Picker Input Box - Large */}
        <div className="flex-row-md-center-center">
            <DateInputBox size="large" />
        </div>
        </div>

    </div>
  );
};

export default Demo;

import React from 'react';
import PropTypes from 'prop-types';
import './Button.css';
import submittingIcon from '../../assets/icons/submitting.svg';

const Button = ({
  variant = 'primary',
  label = '',
  onClick,
  disabled = false,
  type = 'button',
  icon,
  loading = false,
  iconButton = false,
  customButtonColor,
  size = 'medium', // Default to medium size
}) => {
  const buttonClasses = `
    button 
    button-${variant} 
    button-${size}
    ${iconButton ? 'button-icon' : ''}
    ${loading ? 'button-loading' : ''}
  `;

  return (
    <button
      type={type}
      className={buttonClasses}
      onClick={onClick}
      disabled={disabled || loading}
      style={customButtonColor ? { backgroundColor: customButtonColor } : {}}
    >
      {variant === 'submit' && loading && (
        <img src={submittingIcon} alt="Submitting" className="icon-loading" />
      )}

      {!loading && icon && <img src={icon} alt="icon" className="icon" />}

      {!loading && label && <span className="button-label">{label}</span>}

      {loading && variant === 'submit' && <span className="button-label">Submitting...</span>}
    </button>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf([
    'cta',
    'primary',
    'secondary',
    'tertiary',
    'submit',
    'link',
    'danger',
    'icon-button',
  ]),
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  icon: PropTypes.string,
  loading: PropTypes.bool,
  iconButton: PropTypes.bool,
  customButtonColor: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'auto', 'fullWidth']),
};

export default Button;

import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../userData/UserContext';
import rebridLogo from '../../assets/icons/Rebrid.svg';
import cart from '../../assets/icons/cart.svg';
import menuOutline from '../../assets/icons/menu-outline.svg';
import menuFill from '../../assets/icons/menu-fill.svg';
import Button from '../buttons/Button';
import axios from 'axios';
import './Navbar.css';
import Cookies from 'js-cookie';

const Navbar = () => {
  const { user, setUser } = useContext(UserContext);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const navigate = useNavigate();
  const profileRef = useRef(null);

  useEffect(() => {
    const fetchProfilePhoto = async () => {
      if (user && user.username) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_SERVER_BASE_URL}/api/seeker/get-profile/${user.username}`
          );
          setProfilePhoto(response.data.profilePhoto || null);
        } catch (error) {
          console.error('Error fetching profile photo:', error);
        }
      }
    };
    fetchProfilePhoto();
  }, [user]);

  const handleLogout = async () => {
    console.log('Logging out...');
    try {
      await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/seeker/logout`);
      Cookies.remove('token', { path: '/' });
      Cookies.remove('userInfo', { path: '/' });
      console.log('Cookies after removal:', Cookies.get());
      setUser(null);
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeProfileDropdown = (e) => {
    if (profileRef.current && !profileRef.current.contains(e.target)) {
      setIsProfileOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', closeProfileDropdown);
    return () => {
      document.removeEventListener('mousedown', closeProfileDropdown);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="flex-row-md-center-center">
          {/* Menu Icon */}
          <img
            src={isMenuOpen ? menuFill : menuOutline}
            alt="Menu"
            className="menu-icon"
            onClick={toggleMenu}
          />

          {/* Logo */}
          <Link to="/" className="navbar-logo">
            <img src={rebridLogo} alt="Rebrid Logo" height="18" />
          </Link>
        </div>

        {/* Center: Links */}
        <div className="navbar-links">
          <Link to="/explore">Explore</Link>
          <Link to="/register">Wishlist</Link>
          <Link to="/supplier">Supplier</Link>
        </div>

        {/* Right: Actions */}
        <div className="navbar-actions">
          {user ? (
            <div className="flex-row-md-center-center">
              <Link to="/cart" className="cart-empty">
                <img src={cart} alt="Cart Icon" height="24" />
              </Link>

              {/* Profile Circle */}
              <div ref={profileRef} className="profile-container">
              <div className="profile-circle"
                    style={{
                      backgroundImage: profilePhoto ? `url(${profilePhoto})` : 'none',
                      backgroundColor: profilePhoto ? 'transparent' : 'var(--neutral-300)',
                    }}
                    onClick={() => setIsProfileOpen(!isProfileOpen)}
                  >
                    {!profilePhoto && (
                      <span className="profile-initial">
                        {user.username.charAt(0).toUpperCase()}
                      </span>
                    )}
                  </div>

                {isProfileOpen && (
                  <div className="profile-dropdown">
                    <Link to={`/${user.userType.toLowerCase()}-profile`} onClick={() => setIsProfileOpen(false)}>Profile</Link>
                    <Link to="/my-bookings" onClick={() => setIsProfileOpen(false)}>My Bookings</Link>
                    <Link to="/seeker-account-settings" onClick={() => setIsProfileOpen(false)}>Account Settings</Link>
                    <Button label="Logout" variant="secondary" onClick={handleLogout} />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="navbar-auth-buttons flex-row-sm-center-center">
              <Button label="Register" variant="cta" size="small" onClick={() => navigate('/register')} />
              <Button label="Login" variant="secondary" size="small" onClick={() => navigate('/login')} />
              <Button label="Feedback" variant="tertiary" size="small" onClick={() => navigate('/feedback')} />
            </div>
          )}
        </div>
      </div>

      {/* Dropdown for mobile view */}
      {isMenuOpen && (
        <div className="navbar-dropdown">
          <Link to="/explore" onClick={toggleMenu}>Explore</Link>
          <Link to="/supplier" onClick={toggleMenu}>Supplier</Link>
          <Link to="/register" onClick={toggleMenu}>Wishlist</Link>
          <Link to="/feedback" onClick={toggleMenu}>Feedback</Link>

          {user && user.userType.toLowerCase() === 'supplier' && (
            <>
              <Link to="/SupplierProfile" onClick={toggleMenu}>Profile</Link>
              <Link to="/bookings" onClick={toggleMenu}>Bookings</Link>
              <Link to="/products" onClick={toggleMenu}>Products</Link>
              <Link to="/analytics" onClick={toggleMenu}>Analytics</Link>
              <Link to="/financials" onClick={toggleMenu}>Financials</Link>
            </>
          )}

          {user && user.userType.toLowerCase() === 'rebridadmin' && (
            <>
              <Link to="/AdminProfile" onClick={toggleMenu}>Profile</Link>
              <Link to="/system-health" onClick={toggleMenu}>System Health</Link>
              <Link to="/users" onClick={toggleMenu}>Users</Link>
              <Link to="/add-edit-admin" onClick={toggleMenu}>Add/Edit Admin</Link>
              <Link to="/products" onClick={toggleMenu}>Products</Link>
              <Link to="/bookings" onClick={toggleMenu}>Bookings</Link>
              <Link to="/analytics" onClick={toggleMenu}>Analytics</Link>
              <Link to="/financials" onClick={toggleMenu}>Financials</Link>
              <Link to="/platform-settings" onClick={toggleMenu}>Platform Settings</Link>
              <Link to="/tickets" onClick={toggleMenu}>Tickets</Link>
            </>
          )}

          {!user && (
            <div className="flex-column-sm-center-center navbar-dropdown-loginbuttons">
              <Button label="Register" variant="cta" size="large" onClick={() => navigate('/register')} />
              <Button label="Login" variant="secondary" size="large" onClick={() => navigate('/login')} />
            </div>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;

import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../components/userData/UserContext';
import UploadProfilePhoto from '../components/ImageUpload/UploadProfilePhoto';
import InputBox from '../components/inputBox/InputBox';
import DateInputBox from '../components/inputBox/DateInputBox';
import SearchableSingleSelectDropdown from '../components/dropdowns/SearchableSingleSelectDropdown';
import Button from '../components/buttons/Button';
import axios from 'axios';
import './SeekerProfileEdit.css';
import countries from '../json/countries.json';
import currencies from '../json/currencies.json';
import languages from '../json/languages.json';
import UploadCoverPhoto from '../components/ImageUpload/UploadCoverPhoto';

const SeekerProfileEdit = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: '',
    firstName: '',
    lastName: '',
    birthday: '',
    language: '',
    currency: '',
    country: '',
  });

  const [loading, setLoading] = useState(true); // Track loading state

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/seeker/get-profile/${user.username}`
        );
        const data = response.data;
  
        setFormData({
          username: data.username,
          firstName: data.firstName || '',
          lastName: data.lastName || '',
          birthday: data.birthday || '',
          language: data.language || '',
          currency: data.currency || '',
          country: data.country || '',
          profilePhoto: data.profilePhoto || '',
          coverPhoto: data.coverPhoto || '',
        });
      } catch (error) {
        console.error('Error fetching profile data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchProfile();
  }, [user.username]);

  const handleCoverPhotoUpload = async (url) => {
    try {
      await axios.put(`${process.env.REACT_APP_SERVER_BASE_URL}/api/seeker/update-profile`, {
        theUserName: user.username,
        coverPhoto: url,
      });
      console.log(`Cover photo uploaded successfully: ${url}`);
      setFormData((prevData) => ({ ...prevData, coverPhoto: url }));
    } catch (error) {
      console.error('Error uploading cover photo:', error);
    }
  };
  
  const handlePhotoUpload = async (url) => {
    try {
      await axios.put(`${process.env.REACT_APP_SERVER_BASE_URL}/api/seeker/update-profile`, {
        theUserName: user.username,
        profilePhoto: url,
      });
      console.log(`Profile photo uploaded successfully: ${url}`);
  
      // Update the formData state with the new profile photo URL
      setFormData((prevData) => ({ ...prevData, profilePhoto: url }));
    } catch (error) {
      console.error('Error uploading profile photo:', error);
    }
  };  

  const handleInputChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${process.env.REACT_APP_SERVER_BASE_URL}/api/seeker/update-profile`, {
        theUserName: user.username,
        ...formData,
      });
      navigate('/seeker-profile');
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  if (!user) {
    navigate('/login');
    return null;
  }

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    
    <div className="seeker-profile-edit-page">

        {/* Cover Photo Upload */}
              <div className="form-group">
          <UploadCoverPhoto
          theUserName={user.username}
          onUploadComplete={handleCoverPhotoUpload}
          imageUrl={formData.coverPhoto}
          imageSize="Max 2MB, 2000x400px"
        />
        </div>
      
        <div className="seeker-profile-edit-container">
      <h2>Edit Your Profile</h2>
      <form onSubmit={handleSubmit} className="seeker-profile-edit-form">

        {/* Profile Photo Upload */}
        <div className="form-group">
          <UploadProfilePhoto
            size="medium"
            theUserName={user.username}
            onUploadComplete={(url) => handlePhotoUpload(url, 'profilePhoto')}
            imageUrl={formData.profilePhoto}
            imageSize="Max 1MB, 800x800px"
          />
        </div>

        {/* First Name */}
        <div className="form-group">
          <label>First Name</label>
          <InputBox
            placeholder="Enter First Name"
            value={formData.firstName}
            onChange={(e) => handleInputChange('firstName', e.target.value)}
            size="large"
          />
        </div>

        {/* Last Name */}
        <div className="form-group">
          <label>Last Name</label>
          <InputBox
            placeholder="Enter Last Name"
            value={formData.lastName}
            onChange={(e) => handleInputChange('lastName', e.target.value)}
            size="large"
          />
        </div>

        {/* Birthday */}
        <div className="form-group">
          <label>Birthday</label>
          <DateInputBox
            value={formData.birthday}
            onChange={(date) => handleInputChange('birthday', date)}
            size="large"
          />
        </div>

        {/* Preferred Language Dropdown */}
        <div className="form-group">
          <label>Preferred Language</label>
          <SearchableSingleSelectDropdown
            data={languages.map((lang) => lang.label)}
            onSelect={(value) => handleInputChange('language', value)}
            placeholder="Select Language"
            initialValue={formData.language}
            size="large"
          />
        </div>

        {/* Preferred Currency Dropdown */}
        <div className="form-group">
          <label>Preferred Currency</label>
          <SearchableSingleSelectDropdown
            data={currencies.map((currency) => currency.name)}
            onSelect={(value) => handleInputChange('currency', value)}
            placeholder="Select Currency"
            size="large"
            initialValue={formData.currency}
          />
        </div>

        {/* Country Dropdown */}
        <div className="form-group">
          <label>Country</label>
          <SearchableSingleSelectDropdown
            data={countries.map((country) => country.name)}
            onSelect={(value) => handleInputChange('country', value)}
            placeholder="Select Country"
            size="large"
            initialValue={formData.country}
          />
        </div>

        {/* Save Changes Button */}
        <Button
          type="submit"
          variant="primary"
          label="Save Changes"
          size="large"
        />
      </form>
      </div>
    </div>
  );
};

export default SeekerProfileEdit;

import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-container">
            <h2>Privacy Policy</h2>
            <p>Effective Date: October 13, 2024</p>

            {/* Introduction */}
            <section>
                <h5>1. Introduction</h5>
                <p>
                    Welcome to Rebrid. We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, and share your data when you visit our website, use our services, or communicate with us. It also outlines your rights under the General Data Protection Regulation (GDPR).
                </p>
                <p>
                    By using our services, you agree to the terms outlined in this Privacy Policy. If you do not agree, please refrain from using our website or services.
                </p>
                <p>
                    **Company Name**: Rebrid <br/>
                    **Email**: <a href="mailto:hello@rebrid.co">hello@rebrid.co</a> <br/>
                    **Website**: <a href="https://www.rebrid.co">https://www.rebrid.co</a> <br/>
                    **Address**: 8E, June Garden, Hong Kong
                </p>
            </section>

            {/* Data We Collect */}
            <section>
                <h5>2. Data We Collect</h5>
                <p>We collect the following types of personal data to provide and improve our services:</p>

                <h6>a. Data You Provide to Us</h6>
                <ul>
                    <li>**Contact Information**: Name, email address, phone number.</li>
                    <li>**Account Information**: Username, password.</li>
                    <li>**Profile Information**: Address, preferences, interests, and any additional information provided during registration.</li>
                    <li>**Booking Information**: Details related to your bookings, such as activity or ticket information.</li>
                    <li>**Payment Information**: Payment method, billing details, and transaction history.</li>
                    <li>**Communication Data**: Any correspondence or feedback provided via email or chat.</li>
                </ul>

                <h6>b. Data We Automatically Collect</h6>
                <ul>
                    <li>**IP Address**: Used for security, fraud prevention, and analytics.</li>
                    <li>**Device Information**: Type of device (e.g., mobile, desktop), operating system, and browser details.</li>
                    <li>**Location Data**: General geolocation based on your IP address (country-level).</li>
                    <li>**Usage Data**: Time and date of access, pages viewed, clicks, and interactions.</li>
                    <li>**Referrer URL**: The source that led you to our website (e.g., external link).</li>
                    <li>**Watch History**: Activities, rentals, or tickets viewed on Rebrid.</li>
                    <li>**Error Tracking**: Information about any crashes or bugs encountered during your session.</li>
                </ul>

                <h6>c. Data We Collect with Your Consent</h6>
                <ul>
                    <li>**Google Analytics**: We use Google Analytics to collect information on how you use our website. This helps us improve our services. Google Analytics anonymizes your IP address to comply with GDPR.</li>
                    <li>**Behavioral Tracking**: User behavioral data (such as clicks and session duration) may be collected for user experience optimization, only with your consent.</li>
                </ul>
            </section>

            {/* How We Use Your Data */}
            <section>
                <h5>3. How We Use Your Data</h5>
                <p>We use the collected data for the following purposes:</p>
                <ul>
                    <li>**To Process Bookings**: Managing and confirming your bookings, purchases, and reservations.</li>
                    <li>**To Personalize Your Experience**: Providing tailored content, recommendations, and services based on your profile and watch history.</li>
                    <li>**To Improve Our Platform**: Monitoring user activity to enhance our services and improve platform performance.</li>
                    <li>**To Ensure Security**: Using your IP address and other identifiers to prevent fraud and unauthorized access.</li>
                    <li>**To Comply with Legal Obligations**: Retaining certain data for accounting, tax, and regulatory compliance.</li>
                </ul>
            </section>

            {/* Legal Basis for Processing */}
            <section>
                <h5>4. Legal Basis for Processing</h5>
                <p>We process your data based on the following legal grounds:</p>
                <ul>
                    <li>**Performance of a Contract**: Processing is necessary for fulfilling the contract between you and Rebrid (e.g., booking services).</li>
                    <li>**Legitimate Interests**: For security, fraud prevention, and improving our platform.</li>
                    <li>**Consent**: For analytics, behavioral tracking, and any marketing activities.</li>
                    <li>**Legal Obligations**: When processing is necessary for complying with legal requirements.</li>
                </ul>
            </section>

            {/* Cookies and Tracking Technologies */}
            <section>
                <h5>5. Cookies and Tracking Technologies</h5>
                <p>Rebrid uses cookies and similar technologies to provide essential services and enhance your user experience. These include:</p>
                <ul>
                    <li>**Essential Cookies**: Required for the proper functioning of our website (e.g., session management).</li>
                    <li>**Analytical Cookies**: For tracking and analyzing user behavior (e.g., Google Analytics) to improve our services, with your consent.</li>
                </ul>
                <p>You can manage your cookie preferences through your browser settings, but disabling cookies may impact certain features of our website.</p>
            </section>

            {/* Data Sharing */}
            <section>
                <h5>6. Data Sharing</h5>
                <p>We do not sell your personal data to third parties. However, we may share your data with the following entities:</p>
                <ul>
                    <li>**Service Providers**: Third-party providers who assist with payment processing, hosting, and customer service.</li>
                    <li>**Legal Authorities**: When required by law, to comply with legal obligations or court orders.</li>
                    <li>**Business Transfers**: If Rebrid is involved in a merger, acquisition, or asset sale, your data may be transferred as part of the business transaction.</li>
                </ul>
            </section>
            {/* International Data Transfers */}
            <section>
                <h5>7. International Data Transfers</h5>
                <p>
                    Rebrid operates globally, and some of your personal data may be transferred outside the European Economic Area (EEA). We ensure that appropriate safeguards are in place for these transfers, including:
                </p>
                <ul>
                    <li>Standard Contractual Clauses</li>
                    <li>Adequacy decisions from the European Commission</li>
                </ul>
                <p>
                    If you would like to know more about the specific safeguards in place for international data transfers, please contact us at <a href="mailto:hello@rebrid.co">hello@rebrid.co</a>.
                </p>
            </section>

            {/* Data Retention */}
            <section>
                <h5>8. Data Retention</h5>
                <p>
                    We retain your personal data only for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. The specific retention periods depend on the type of data and the purpose for which it was collected, but generally follow these principles:
                </p>
                <ul>
                    <li>Data related to bookings and transactions will be retained for the duration of your contract with Rebrid and for the purposes of compliance with legal and regulatory obligations.</li>
                    <li>Communication data may be stored for quality assurance and service improvement purposes, but will be deleted when no longer needed.</li>
                    <li>Data collected for marketing purposes will be retained as long as you have not withdrawn your consent.</li>
                </ul>
                <p>
                    Once your data is no longer needed, we will securely delete or anonymize it.
                </p>
            </section>

            {/* Your Rights Under GDPR */}
            <section>
                <h5>9. Your Rights Under GDPR</h5>
                <p>
                    As a user, you have the following rights under the General Data Protection Regulation (GDPR):
                </p>
                <ul>
                    <li>**Right to Access**: You have the right to request access to the personal data we hold about you.</li>
                    <li>**Right to Rectification**: You can ask us to correct inaccuracies in your personal data.</li>
                    <li>**Right to Erasure**: You have the right to request the deletion of your personal data, subject to certain conditions.</li>
                    <li>**Right to Restrict Processing**: You can ask us to limit the processing of your personal data under certain circumstances.</li>
                    <li>**Right to Data Portability**: You can request to receive your personal data in a structured, commonly used, and machine-readable format.</li>
                    <li>**Right to Object**: You can object to the processing of your personal data, particularly in the case of direct marketing or profiling.</li>
                    <li>**Right to Withdraw Consent**: If processing is based on consent, you have the right to withdraw your consent at any time.</li>
                </ul>
                <p>
                    To exercise any of these rights, please contact us at <a href="mailto:hello@rebrid.co">hello@rebrid.co</a>. We will respond to your request in accordance with applicable data protection laws.
                </p>
            </section>

            {/* Data Security */}
            <section>
                <h5>10. Data Security</h5>
                <p>
                    We implement appropriate technical and organizational measures to ensure the security of your personal data. This includes protecting your data against unauthorized access, loss, misuse, or alteration. However, please note that no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
                </p>
            </section>

            {/* Changes to This Privacy Policy */}
            <section>
                <h5>11. Changes to This Privacy Policy</h5>
                <p>
                    We may update this Privacy Policy from time to time to reflect changes in our practices, legal obligations, or for other operational, legal, or regulatory reasons. Any changes to this policy will be posted on this page, and the "Effective Date" will be updated accordingly.
                </p>
                <p>
                    We encourage you to review this Privacy Policy periodically to stay informed about how we protect your data.
                </p>
            </section>

            {/* Contact Information */}
            <section>
                <h5>12. Contact Information</h5>
                <p>
                    If you have any questions, concerns, or complaints regarding this Privacy Policy or our data protection practices, please contact us at:
                </p>
                <p>
                    **Email**: <a href="mailto:hello@rebrid.co">hello@rebrid.co</a><br/>
                    **Address**: 8E, June Garden, Hong Kong
                </p>
            </section>

            {/* Additional GDPR Sections */}
            <section>
                <h5>13. Automated Decision-Making</h5>
                <p>
                    We do not use your personal data for automated decision-making, including profiling, which may significantly affect you.
                </p>
            </section>

            <section>
                <h5>14. Children’s Privacy</h5>
                <p>
                    Our services are not directed to individuals under the age of 16. We do not knowingly collect personal data from individuals under the age of 16. If we become aware that we have collected personal data from a child without verification of parental consent, we will delete that information promptly.
                </p>
            </section>

            <section>
                <h5>15. Third-Party Links</h5>
                <p>
                    Our website may contain links to other websites or services operated by third parties. We are not responsible for the privacy practices of such third parties. We encourage you to review the privacy policies of any third-party services before providing them with your personal data.
                </p>
            </section>

            <section>
                <h5>16. Marketing Communications</h5>
                <p>
                    With your consent, we may send you marketing communications, including newsletters, promotional offers, or other updates about our services. You can opt-out of receiving these communications at any time by clicking the "unsubscribe" link in the email or contacting us at <a href="mailto:hello@rebrid.co">hello@rebrid.co</a>.
                </p>
            </section>
            {/* Legal Disclosures */}
            <section>
                <h5>17. Legal Disclosures</h5>
                <p>
                    In certain circumstances, we may be required to disclose your personal data by law or in response to valid requests by public authorities (such as a court or government agency). We may also disclose your personal data to enforce our policies, respond to claims of illegal activities or violations of rights, or to protect the rights, property, or safety of Rebrid, our users, or others.
                </p>
            </section>

            {/* Data Processors and Third-Party Services */}
            <section>
                <h5>18. Data Processors and Third-Party Services</h5>
                <p>
                    We may engage third-party service providers (data processors) to perform services on our behalf, such as hosting, payment processing, and customer support. These third parties will only have access to personal data necessary to perform their functions, and they are obligated to keep your information confidential and comply with data protection regulations.
                </p>
                <p>
                    Examples of third-party services we work with include payment gateways, cloud hosting platforms, and email marketing providers.
                </p>
            </section>

            {/* International Transfers */}
            <section>
                <h5>19. International Transfers</h5>
                <p>
                    Your personal data may be transferred to and processed in countries outside the European Economic Area (EEA), including countries that may not provide the same level of data protection as the laws in your jurisdiction. We ensure that appropriate safeguards are in place when such transfers occur, including the use of Standard Contractual Clauses or reliance on an adequacy decision from the European Commission.
                </p>
            </section>

            {/* Rights of Individuals in the EEA */}
            <section>
                <h5>20. Rights of Individuals in the EEA</h5>
                <p>
                    If you are a resident of the European Economic Area (EEA), you have the following rights under GDPR:
                </p>
                <ul>
                    <li>**Right to Information**: You have the right to be informed about how we use your personal data.</li>
                    <li>**Right to Access**: You can request access to the personal data we hold about you.</li>
                    <li>**Right to Rectification**: You have the right to request corrections to any inaccuracies in your personal data.</li>
                    <li>**Right to Erasure**: You can request that we delete your personal data under certain circumstances.</li>
                    <li>**Right to Restrict Processing**: You have the right to ask us to restrict how we process your data.</li>
                    <li>**Right to Object**: You can object to certain types of data processing, such as direct marketing.</li>
                    <li>**Right to Data Portability**: You can request that your data be transferred to another provider in a machine-readable format.</li>
                    <li>**Right to Withdraw Consent**: If you provided consent for data processing, you can withdraw that consent at any time.</li>
                </ul>
            </section>

            {/* Data Breach Notification */}
            <section>
                <h5>21. Data Breach Notification</h5>
                <p>
                    In the event of a data breach, we will notify you and any applicable supervisory authority within 72 hours if the breach is likely to result in a risk to your rights and freedoms, in accordance with applicable data protection laws.
                </p>
            </section>

            {/* California Consumer Privacy Act (CCPA) */}
            <section>
                <h5>22. California Consumer Privacy Act (CCPA)</h5>
                <p>
                    If you are a resident of California, you have additional rights under the California Consumer Privacy Act (CCPA). These rights include:
                </p>
                <ul>
                    <li>**Right to Know**: You have the right to request information about the personal data we collect, use, disclose, and sell (if applicable).</li>
                    <li>**Right to Delete**: You can request that we delete your personal data, subject to certain exceptions.</li>
                    <li>**Right to Opt-Out**: If applicable, you have the right to opt-out of the sale of your personal data.</li>
                    <li>**Non-Discrimination**: You have the right not to be discriminated against for exercising your CCPA rights.</li>
                </ul>
                <p>
                    To exercise your rights under the CCPA, please contact us at <a href="mailto:hello@rebrid.co">hello@rebrid.co</a>.
                </p>
            </section>

            {/* Complaints */}
            <section>
                <h5>23. Complaints</h5>
                <p>
                    If you have any concerns about how we handle your personal data, you have the right to file a complaint with a supervisory authority. In the EEA, you can contact your local data protection authority for more information about your rights and how to exercise them.
                </p>
                <p>
                    For more information or to file a complaint, please contact us at <a href="mailto:hello@rebrid.co">hello@rebrid.co</a>.
                </p>
            </section>
        </div>
    );
};

export default PrivacyPolicy;

import React from 'react';
import Button from '../buttons/Button';
import { trackEssentialsOnly } from '../../utils/track';
import './ConsentModal.css';

const ConsentModal = ({ onAllow, onEssentialsOnly }) => {

    const handleEssentialsOnly = () => {
        localStorage.setItem('cookieConsent', 'essentials');
        try {
          trackEssentialsOnly();  // Essentials-only tracking
          onEssentialsOnly();
        } catch (error) {
          console.error('Error during essentials tracking:', error);
          alert('Failed to track essentials consent. Please try again.');
        }
      };
      

  return (
    <div className="consent-modal">
      <div className="consent-text">
        <p>
          We use cookies to enhance your experience, serve personalized content, and analyze traffic.
          You can allow all cookies or limit them to essentials only.
          Read our <a href="/privacy-policy">Privacy Policy</a>.
        </p>
      </div>
      <div className="consent-buttons">
        <Button variant="cta" label="Allow" onClick={onAllow} />
        <Button variant="tertiary" label="Essentials Only" onClick={handleEssentialsOnly} />
      </div>
    </div>
  );
};

export default ConsentModal;
